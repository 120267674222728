import Section from '@stories/atoms/Section/Section';
import CategoryList from '@stories/organisms/CategoryList/CategoryList';

import type { TCategoryListProps } from '@stories/organisms/CategoryList/CategoryList';

const CategoryListSection: React.FC<TCategoryListProps & { headline: string }> = ({ headline, ...props }) => {
  return (
    <Section headline={{ title: headline }} sectionWidth="narrow">
      <CategoryList {...props} />
    </Section>
  );
};

export default CategoryListSection;
